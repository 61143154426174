<template>
    <div class="mensagens-padrao-page">
        <Breadcrumb titulo="MODULO PADRAO" :items="itensBreacrumb" backgroundColor="#259cd3" />
        <div class="container-fluid py-3">

            <div class="row pt-3">
                <div class="col-12">
                    <tabela-mensagens-padrao :items="mensagensPadrao"
                                             :selecionado="selecionado"
                                             :loading="mensagensPadraoLoading"
                                             @criar-mensagem="abrirModalCriacao"
                                             @editar="abrirModalEdicao"
                                             @remover="removerMsg" 
                                             @paginar="paginar" />
                </div>
            </div>

        </div>

        <ModalMensagensPadrao ref="modal_mensagens_padrao"
                              :listaCanais="listaCanais"
                              :listaTabulacoes="listaTabulacoes"
                              :listaItensTabulacoes="listaItensTabulacoes"
                              @buscaItensTabulacao="buscaItensTabulacao"
                              @ok="salvarMensagem" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import TabelaMensagensPadrao from '@/components/tabelas/TabelaMensagensPadrao.vue'
    import ModalMensagensPadrao from '@/components/modulo-email/ModalMensagensPadrao.vue'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'

    export default {
        name: 'MensagensPadraoPage',

        components: {
            Breadcrumb,
            TabelaMensagensPadrao,
            ModalMensagensPadrao,
            BotaoPadrao,
        },

        data: function () {
            return {
                itensBreacrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fa fa-bullhorn',
                        texto: 'Campanhas',
                        link: '/campanhas'
                    },
                    {
                        id: '3',
                        classe: 'fa fa-envelope-o',
                        texto: 'Mensagens Padrao',
                        link: '/mensagens-padrao'
                    },
                ],
                selecionado: 1,
            }
        },

        computed: {
            ...mapGetters({
                mensagensPadrao: 'moduloPadrao/mensagensPadrao',
                mensagensPadraoLoading: 'moduloPadrao/mensagensPadraoLoading',
                listaCanais: 'canais/listaCanais',
                listaTabulacoes: 'tabulacao/listaTabulacoes',
                listaItensTabulacoes: 'tabulacaoItens/listaItensTabulacoes'
            }),
        },

        created() {
            this.buscaMensagensPadrao(0)
            this.buscaCanaisPadrao()
            this.buscaTabulacoes()
            this.buscaItensTabulacoes()
        },

        methods: {
            ...mapActions({
                buscaCanaisPadrao: 'canais/buscaCanais',
                buscaTabulacoes: 'tabulacao/buscaTabulacoes',
                buscaItensTabulacoes: 'tabulacaoItens/buscaItensTabulacoes',
                buscaItensByTabulacaoPadrao: 'tabulacaoItens/buscaItensByTabulacao',
                setLoadingGlobal: 'setLoadingGlobal',
                buscaMensagensPadrao: 'moduloPadrao/buscaMensagens',
                adicionarMensagemPadrao: 'moduloPadrao/adicionar',
                editarMensagemPadrao: 'moduloPadrao/editar',
                removerMensagemPadrao: 'moduloPadrao/remover',
                mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
                mostrarErro: 'mostradorDeMensagens/mostrarErro',
            }),
            abrirModalCriacao() {
                this.$refs.modal_mensagens_padrao.show()
            },
            abrirModalEdicao(id) {
                if (!id) {
                    return
                }
                let msgEdicao = this.mensagensPadrao.find(msg => msg.Id == id)
                if (!msgEdicao) {
                    return
                }
                this.$refs.modal_mensagens_padrao.show(msgEdicao)
            },
            async buscaItensTabulacao(idTabulacao) {
                try {
                    await this.buscaItensByTabulacaoPadrao(idTabulacao)
                    if (!this.listaItensTabulacoes) {
                        return
                    }
                    this.$refs.listaItensTabulacoes = this.listaItensTabulacoes
                } catch (e) {
                    this.mostrarErro('Houve um erro ao tentar buscar os itens por tabulação.')
                    console.log('erro ao buscar os itens')
                }
            },
            async salvarMensagem(msg) {
                this.setLoadingGlobal(true)
                try {
                    if (!msg.Id) {
                        await this.adicionarMensagemPadrao(msg)
                    } else {
                        await this.editarMensagemPadrao(msg)
                    }
                    this.$bvToast.toast('A mensagem foi salva.', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                   
                } catch (e) {
                    this.mostrarErro('Houve um erro ao tentar salvar a mensagem.')
                    console.log('erro ao salvar mensagem', e)
                }
                this.setLoadingGlobal(false)
            },
            async removerMsg(id) {
                this.setLoadingGlobal(true)
                try {
                    await this.removerMensagemPadrao(id);
                    this.$bvToast.toast('Remoção bem sucessida.', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    });
                    await this.buscaMensagensPadrao(0);
                    this.selecionado = 1;
                    
                } catch (e) {
                    console.log('erro ao remover mensagem', e)
                    this.mostrarErro('Houve um erro na exclusão da mensagem.')
                }
                this.setLoadingGlobal(false)
            },
            async paginar(selecionado) {
                try {
                    if (selecionado != null) {
                        await this.buscaMensagensPadrao((selecionado - 1) * 10)
                    }
                } catch (e) {
                    console.log('erro ao paginar', e)
                    this.mostrarErro('Houve um erro ao paginar, tente novamente.')
                }
            }

        },
    }
</script>

<style scoped>
    .mensagens-padrao-page-sac-input {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
    }
</style>