<template>
    <b-modal v-model="exibe"
             hide-footer centered
             title="Nova Mensagem Pré-Definida"
             size="lg"
             no-enforce-focus>
        <template v-slot:default="{ cancel, ok }">
            <form id="modal-mensagens-padrao-form"
                  @submit.prevent="salvar(ok)">
                <div class="container">

                    <div class="row pt-2">
                        <div class="col-8">
                            <label for="" style="font-size: 14px;" class="font-weight-bold">Título:</label>
                            <input type="text" class="modal-mensagens-padrao-input" v-model="titulo">
                        </div>
                        <div class="col-4">
                            <label for="" style="font-size: 14px;" class="font-weight-bold">Disponível:</label>
                            <select class="modal-mensagens-padrao-input" v-model="estaDisponivel">
                                <option value="null">Selecione...</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </div>
                    </div>

                    <div class="row pt-2">
                        <div class="col-12">
                            <label for="" style="font-size: 14px;" class="font-weight-bold">Mensagem:</label>
                            <HtmlEditor :dynamicArea="false" v-model="mensagemHtml" class="p-0" />
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-8">
                            <label for="" style="font-size: 14px;" class="font-weight-bold">Seleciona um Canal:*</label>
                            <select class="modal-mensagens-padrao-input" v-model="tipoCanalSelecionado" required>
                                <option value="">Selecione...</option>
                                <option v-for="(canal, index) in listaCanais" :value="canal.Tipo">
                                    {{canal.Nome}}
                                </option>
                            </select>
                        </div>

                        <div class="col-4 align-self-end" style="font-size: 16px;">
                            <input type="checkbox" v-model="envioDireto" />

                            <span style="vertical-align: text-bottom;" class="px-2">Envie Direto</span>
                        </div>
                    </div>

                    <div class="border-top mt-2">
                        <div class="row pt-2">
                            <div class="col-12">
                                <h6 style="text-align: center !important;">Configurações de Envio por Tabulação</h6>
                            </div>
                        </div>

                        <div class="row pt">
                            <div class="col-4">
                                <label for="" style="font-size: 14px;" class="font-weight-bold">Habilita Tabulação:</label>
                                <select class="modal-mensagens-padrao-input" v-model="habTabulacao">
                                    <option value="null">Selecione...</option>
                                    <option value="true">Sim</option>
                                    <option value="false">Não</option>
                                </select>
                            </div>

                            <div v-show="habTabulacao === 'true'" class="col-8">
                                <label for="" style="font-size: 14px;" class="font-weight-bold">Tabulação:</label>
                                <select class="modal-mensagens-padrao-input" v-model="idTabulacaoSelecionado" @change="buscaItensTabulacao($event)">
                                    <option value="">Selecione...</option>
                                    <option v-for="(tabulacao, index) in listaTabulacoes" data-toggle="tooltip" :title="tabulacao.Titulo.length > 52 ? tabulacao.Titulo : ''" :value="tabulacao.Id">
                                        {{tabulacao.Titulo.length > 52 ? tabulacao.Titulo.substring(0, 52) + '...' : tabulacao.Titulo}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div v-show="idTabulacaoSelecionado !== '' && habTabulacao === 'true'" class="col-8">
                                <label for="" style="font-size: 14px;" class="font-weight-bold">Items Tabulação:</label>
                                <select class="modal-mensagens-padrao-input" v-model="idTabulacaoItemSelecioando">
                                    <option value="">Selecione...</option>
                                    <option v-for="(tabulacaoItem, index) in listaItensTabulacoes" :value="tabulacaoItem.Id">
                                        {{tabulacaoItem.Titulo}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row py-2">
                        <div class="col-6">
                            <ul class="modal-mensagens-padrao-lista-erros">
                                <li v-for="(erro, i) in erros" :key="i">{{ erro }}</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <botao-modal-ok class="float-right ml-2"
                                            type="submit">
                                Confirmar
                            </botao-modal-ok>
                            <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                        </div>
                    </div>

                </div>
            </form>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
	import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
	import HtmlEditor from "@/components/inputs/HtmlEditor";

    export default {
        name: 'ModalMensagensPadrao',

        components: {
            BotaoModalOk,
            BotaoModalCancel,
            HtmlEditor,
        },

        props: {
            setoresDisponiveis: {
                type: Array,
                default: null
            },
            listaCanais: {
                type: Array,
                default: null
            },
            listaTabulacoes: {
                type: Array,
                default: null
            },
            listaItensTabulacoes: {
                type: Array,
                default: null
            }
        },

        data: function () {
            return {
                exibe: false,
                erros: [],
                id: '',
                titulo: '',
                estaDisponivel: null,
                mensagemHtml: '',
                habTabulacao: null,
                tipoCanalSelecionado: '',
                idTabulacaoSelecionado: '',
                idTabulacaoItemSelecioando: '',
                envioDireto: false
            }
        },

        methods: {
            show(msg) {
                this.limpa()
                this.exibe = true

                if (!msg) {
                    // Modo de criação, só abre o modal limpo
                    return
                }

                this.id = msg.Id
                this.titulo = msg.Nome
                this.estaDisponivel = msg.Disponivel.toString()
                this.mensagemHtml = msg.MensagenHtml
                this.envioDireto = msg.EnvioDireto
                this.habTabulacao = msg.HabTabulacao.toLowerCase()
            },
            hide() {
                this.exibe = false
            },
            async buscaItensTabulacao(tabulacao) {
                if (tabulacao.target.value !== '') await this.$emit('buscaItensTabulacao', tabulacao.target.value);
            },
            salvar(ok) {
                this.erros = []

                if (!this.titulo || this.titulo.length < 3) {
                    this.erros.push('Você deve inserir um nome com pelo menos três(3) caracteres!')
                }

                if (this.erros.length > 0) {
                    return
                }

                this.mensagemHtml = this.$refs.editorPadrao.getContent()

                console.log(this.envioDireto)

                const form = {
                    Id: this.id,
                    Nome: this.titulo,
                    Tipo: this.tipoCanalSelecionado,
                    Disponivel: this.estaDisponivel === 'true',
                    MensagenHtml: this.mensagemHtml,
                    TabulacaoHabilitado: this.habTabulacao,
                    idTabulacao: this.idTabulacaoSelecionado,
                    idTabulacaoItem: this.idTabulacaoItemSelecioando,
                    EnvioDireto: this.envioDireto
                }

                console.log(form.EnvioDireto)

                this.$emit('ok', form)
                // Metodo Ok do bootstrap que fecha o modal
                ok()
            },
            limpa() {
                this.erros = []
                this.id = ''
                this.titulo = ''
                this.estaDisponivel = null
                this.mensagemHtml = ''
                this.habTabulacao = null
                this.tipoCanalSelecionado = ''
                this.idTabulacaoSelecionado = ''
                this.idTabulacaoItemSelecioando = ''
                this.envioDireto = false
            }
        }
    }
</script>

<style scoped>
    .modal-mensagens-padrao-lista-erros {
        color: red;
    }

    .modal-mensagens-padrao-input {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }
</style>