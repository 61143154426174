<template>
    <div class="tabela-mensagens-padrao">

        <div class="container-fluid w-100">
            <div class="row">

                <div class="d-flex col-12 tabela-mensagens-padrao-acoes px-0">
                    <div class="mr-auto d-flex">
                        <input-pesquisa @filtrar="onFiltrarTexto" class="tabela-mensagens-padrao-input-pesquisar mr-3" />
                        <botao-padrao @click="abrirModalExportacao" texto="Exportar relatório" class="tabela-mensagens-padrao-botao-exportar" />
                    </div>
                    <botao-padrao @click="criarMensagem" texto="Nova mensagem" class="tabela-mensagens-padrao-botao-exportar" />
                </div>

            </div>
        </div>

        <b-table v-model="visibleRows"
                 :items="itemsFormatados"
                 :fields="fields"
                 :filter="filter"
                 responsive
                 striped
                 hover
                 :busy="loading"
                 class="tabela-mensagens-padrao-btable app-scroll-custom">

            <template #table-busy>
                <div class="text-center py-2">
                    <b-spinner class="align-middle ml-1"></b-spinner>
                    <strong class="px-1">Carregando...</strong>
                </div>
            </template>

            <template #cell(MensagenHtml)="row">
                <span class="app-ck-html-container" v-html="row.value">
                </span>
            </template>

            <template #cell(acoes)="row">
                <div class="text-center">
                    <botao-tab-edit @click="$emit('editar', row.item.Id)" />
                    <botao-tab-remove @click="$emit('remover', row.item.Id)" />
                </div>
            </template>

        </b-table>

        <paginacao-seta :offset="offset"
                        :selecionado="selecionado"
                        @eventoPaginacao="paginar" />

        <ModalExportacaoTabelas ref="modal_exportacao"
                                :camposDiponiveisParaExportacao="fields.filter(campo => campo.key !== 'acoes')"
                                @exportar="exportarTabela" />

    </div>
</template>

<script>
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import ExportacaoServices from '@/assets/js/services/ExportacaoServices'
    import ModalExportacaoTabelas from '@/components/tabelas/ModalExportacaoTabelas.vue'
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta.vue'

    export default {
        name: 'TabelaMensagensPadrao',

        components: {
            InputPesquisa,
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove,
            ModalExportacaoTabelas,
            PaginacaoSeta,
        },

        props: {
            items: {
                type: Array,
            },
            selecionado: {
                type: Number,
                default: 1
            },
            loading: {
                type: Boolean,
                default: false
            },
        },

        data: function () {
            return {
                fields: [
                    { key: 'Nome', label: 'Nome', sortable: true, sortDirection: 'asc' },
                    { key: 'MensagenHtml', label: 'Mensagem', sortable: true, sortDirection: 'asc' },
                    { key: 'Disponivel', label: 'Disponível', sortable: true, sortDirection: 'asc' },
                    { key: 'acoes', label: 'Ações' }
                ],
                filter: null,
                filtrosDisponiveis: ['Nome'],
                visibleRows: [],
                offset: 0,
            }
        },

        watch: {
            items() {
                this.offset = (Math.floor(this.items[0].Offset / 10) + 1);
            }
        },

        computed: {
            itemsFormatados() {
                return this.items?.map(item => {
                    return {
                        ...item,
                        MensagenHtml: item.MensagenHtml ? item.MensagenHtml.Length > 30 ? item.MensagenHtml.Substring(0, 30) + '...' : item.MensagenHtml : '',
                        Disponivel: item.Disponivel ? 'Sim' : 'Nao',
                    }
                }) || []
            },
        },

        methods: {
            onFiltrarTexto(texto) {
                this.filter = texto
            },
            criarMensagem() {
                this.$emit('criar-mensagem')
            },
            abrirModalExportacao() {
                this.$refs.modal_exportacao.show()
            },
            exportarTabela(campos) {
                let linhasComColunasVisiveis = this.visibleRows
                    // Cria um array com os labels como chave pois serão exibidos na planilha como cabeçalho
                    .map(row => {
                        let novaRow = {}
                        campos.forEach(campo => {
                            novaRow[campo.label] = row[campo.key]
                        })
                        return novaRow
                    })
                ExportacaoServices.exportarPlanilhaSimples(linhasComColunasVisiveis, 'tabela_mensagens_email.xlsx')
            },
            paginar(selecionado) {
                this.$emit('paginar', selecionado)
            }
        },

    }
</script>

<style scoped>
    .tabela-mensagens-padrao {
        padding: 12px;
        background-color: var(--cinza-3);
        width: 100%;
    }

    .tabela-mensagens-padrao-acoes {
        height: 45px;
        padding-bottom: 10px
    }

    .tabela-mensagens-padrao-input-pesquisar {
        width: 400px !important;
    }

    .tabela-mensagens-padrao-botao-exportar {
        width: 180px;
        background-color: var(--cinza-2);
    }

    .tabela-mensagens-padrao-btable {
        background-color: #fff;
        height: calc(100% - 85px);
        overflow-y: auto;
        margin-bottom: 0;
        font-size: 12px;
    }
</style>